import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Deload Week`}</strong></p>
    <p>{`3 rounds, not timed, of:`}</p>
    <p>{`10 Step Ups per leg`}</p>
    <p>{`10 Bent Leg Bridge`}</p>
    <p>{`10 Reverse Lunges per leg`}</p>
    <p>{`1:00 Plank`}</p>
    <p>{`then,`}</p>
    <p>{`Assault Bike Intervals`}</p>
    <p>{`5 Rounds of :30 hard, :30 rest`}</p>
    <p>{`then,`}</p>
    <p>{`Lowerbody & Core Mobility Work`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      